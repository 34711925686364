@import 'assets/scss/custom-theme.scss';
@tailwind base;
@tailwind components;
@tailwind utilities;
@import '../node_modules/flag-icons/css/flag-icons.min.css';

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
  line-height: 22px;
}

h1{
  font-size: 2em;
  font-weight: bold;
  line-height: 1.8em;
}

h2{
  font-size: 1.5em;
  font-weight: bold;
  line-height: 1.4em;
}
h3{
  font-size: 1.2em;
  font-weight: bold;
  line-height: 1.1em;
}

.white-bg {
  background-color: white;
}

.text-center {
  text-align: center;
}

.margin-right-10 {
  margin-right: 10px;
}

.margin-right-20 {
  margin-right: 10px;
}

.margin-top-20 {
  margin-top: 20px;
}

p {
  margin: 10px;
  font-size: 16px;
  line-height: 22px;
  color: #525252;
}

a {
  color: #ee7711;
  font-size: 16px;
}

a:hover {
  text-decoration: none;
  cursor: pointer;
}

.width-100 {
  width: 100%;
}

.mat-mdc-form-field-appearance-outline .mat-mdc-form-field-infix {
  padding: 5px 0 10px 0;
}

/** Mat dialog css */
.mat-mdc-dialog-title {
  margin-bottom: 0 !important;
}


// .cdk-overlay-pane{
//   padding: 64px 0;
//   position: relative !important;
// }

.cdk-overlay-container{
  overflow-y: auto !important;
  pointer-events: auto !important;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  pointer-events: none;
}

.cdk-overlay-backdrop{
  position: fixed;
}

.kbk-backdrop{
  background-color: rgba(0,0,0,.75);
}

.mat-expansion-panel-content{
  line-height: 24px !important;
}
.mat-expansion-panel-content a{
  font-size: 15px !important;
}
.mat-expansion-panel-header-title{
  font-weight: 500;
}

.mat-step-icon-selected, .mat-step-icon-state-edit, .mat-step-icon-state-done{
  background-color: #ee7711 !important;
}
.mat-step-icon {
  height: 40px !important;
  width: 40px !important;
  margin-top: -8px;
}

.mat-mdc-progress-spinner circle, .mat-mdc-progress-spinner circle {
   stroke: #ee7711 !important;
}

.mat-mdc-dialog-container .mdc-dialog__surface{
  padding: 20px;
}

/** Mat radio css */
/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
.mat-mdc-radio-label {
  padding-left: 5px;
  padding-bottom: 10px;
}

/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
.radio-image * .mat-radio-container{
  display: none !important;
}

@media only screen and (max-width: 768px) {
  .mat-step-label {
    white-space: normal !important;
    overflow: visible !important;
  }
}
/*cookie consent*/
.light.cc_dialog button.cc_b_ok{
  background-color:#ee7711 !important;
}

.light.cc_dialog{
  max-width: 30% !important;
  padding: 20px 40px !important;
  font-family: Roboto !important;
  border-start-start-radius: 4px !important;
  box-shadow: #111 2px 4px 10px 1px !important;
  background-color: #f9f9f9 !important;
}

// input:-webkit-autofill,
// input:-webkit-autofill:hover, 
// input:-webkit-autofill:focus, 
// input:-webkit-autofill:active{
//     -webkit-box-shadow: 0 0 0 30px white inset !important;
// }
