.upload-container, .upload-container .upload-row, .upload-container .upload-wrapper {
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
}
/*
.upload-wrapper div, span.text{
  justify-items: flex-start !important;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  text-align: center;
}
*/
.accordion-wrapper{
  background-color: #f9f9f9 !important;
}

.mat-expansion-panel-header {
  padding: 16px 24px !important;
  transition: height 225ms cubic-bezier(0.4, 0, 0.2, 1) !important;
}
.mat-expansion-panel-header:hover{
  background-color: #f0f0f0 !important;
}
.mat-expansion-indicator::before, .mat-expansion-indicator::after{
  color: #666;
  border-color: #666;
}
.mat-expansion-panel-body{
  font-size: 14px;
  h3 {
    margin-top: 8px;
  }
}


.mat-mdc-form-field-appearance-outline .mat-mdc-form-field-infix {
  padding: 5px 0 10px 0;
}

/** Mat dialog css */
.mat-mdc-dialog-title {
  margin-bottom: 0 !important;
}
.mdc-notched-outline__notch{
  border-right:none !important;
}

.mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background, .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background{
  border-color: #ee7711 !important;
  background-color: #ee7711 !important;
}
.mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper{
  top: -20px !important;
}
.mat-mdc-form-field-error .error{
  font-size:12px !important;
  margin: -10px 0 10px 0px !important;
}

// .cdk-overlay-pane{
//   padding: 64px 0;
//   position: relative !important;
// }

.cdk-overlay-container{
  overflow-y: auto !important;
  pointer-events: auto !important;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  pointer-events: none;
}

.cdk-overlay-backdrop{
  position: fixed;
}

.kbk-backdrop{
  background-color: rgba(0,0,0,.75);
}

.mat-expansion-panel-content{
  line-height: 24px !important;
}
.mat-expansion-panel-content a{
  font-size: 15px !important;
}
.mat-expansion-panel-header-title{
  font-weight: 500;
}

.mat-step-icon-selected, .mat-step-icon-state-edit, .mat-step-icon-state-done{
  background-color: #ee7711 !important;
}
.mat-step-icon {
  height: 40px !important;
  width: 40px !important;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line,
.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after
{
  top: 45px !important;
}


.mat-mdc-progress-spinner circle, .mat-mdc-progress-spinner circle {
   stroke: #ee7711 !important;
}

.mat-mdc-dialog-container .mdc-dialog__surface{
  padding: 20px;
}

/** Mat radio css */
/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
.mat-mdc-radio-label {
  padding-left: 5px;
  padding-bottom: 10px;
}

.terms-description{
  margin: 20px;
  font-size: 14px;
  h3{
    margin: 10px 0 4px 0;
  }
  .mdc-button{
    margin-bottom: 20px !important;
  }
}

kbk-upload-receipt{
  * {
    font-family: Roboto, "Helvetica Neue", sans-serif;
  }
}